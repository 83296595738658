<template>
  <div>
    <DashboardHome v-if="true" />
  </div>
</template>
<script setup lang="ts">
useHead({
  title: 'Home'
})
</script>
