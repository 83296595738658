<template>
  <div :class="classes">
    <slot />
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  dialogWidth?: boolean
}>()

const classes = computed(() => {
  const classParts: string[] = ['grid grid-cols-1 gap-4']

  if (props.dialogWidth) {
    classParts.push('sm:grid-cols-2 md:grid-cols-3')
  } else {
    classParts.push('sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3')
  }

  return classParts.join(' ')
})
</script>
