<template>
  <div class="space-y-8">
    <CommonHeader />
    <div class="space-y-4">
      <div class="flex items-center justify-between">
        <div>
          <h2 class="h4 font-bold">Functions</h2>
          <div class="text-foreground-2">
            Select a function to create an automation on your project from it!
          </div>
        </div>
        <div class="space-x-2 flex items-center">
          <FormButton
            v-if="core.isLoggedIn"
            size="lg"
            color="card"
            :icon-right="PlusIcon"
            @click="newFunctionDialogOpen = true"
          >
            New
          </FormButton>
          <FormButton :to="functionsRoute" size="lg">View All</FormButton>
        </div>
      </div>
      <FunctionsCardGrid v-if="functions.functions?.items.length">
        <FunctionsCardMarketplace
          v-for="fn in (functions.functions?.items || []).slice(0, 20)"
          :key="fn.functionId"
          :fn="fn"
        />
      </FunctionsCardGrid>
      <div v-else>TODO: Empty state</div>
      <div v-if="functions.functions?.items.length" class="w-ful text-center">
        <FormButton :to="functionsRoute" size="sm" :block="true" color="card">
          View All
        </FormButton>
      </div>
    </div>

    <div v-if="core.isLoggedIn" class="space-y-4">
      <CommonSectionHeader>
        <template #header>Your Automations</template>
        <template #subheader>
          A quick overview of the latest runs from your automations.
        </template>
        <template #actions>
          <FormButton
            size="lg"
            color="card"
            :icon-right="PlusIcon"
            @click="newAutomationDialogOpen = true"
          >
            New
          </FormButton>
          <FormButton size="lg" :to="automationsRoute">View All</FormButton>
        </template>
      </CommonSectionHeader>
      <div v-if="automations.automations?.items.length" class="space-y-4">
        <AutomationsRow
          v-for="automation in (automations.automations?.items || []).slice(0, 20)"
          :key="automation.automationId"
          :automation="automation"
          ring
        />
      </div>
      <div v-else>TODO: Empty state</div>
    </div>

    <CommonHeader class="pt-52 pb-40 mb-40">
      <template #header>How Does Speckle Automate Work?</template>
      <template #subheader>
        <div>
          Speckle Automate allows you to continously run checks on your models as they
          are published. Ensure project data standards, check for design faults or
          create delivery artefacts
          <b>automatically.</b>
        </div>
      </template>
      <template #cta>
        <img src="~~/assets/images/blurby.svg" alt="img" class="h-96" />
      </template>
    </CommonHeader>

    <!-- <div class="flex flex-col items-center w-full">
      <div>New Model Version</div>
      <div class=""></div>
    </div> -->

    <FunctionsCreateDialog v-model:open="newFunctionDialogOpen" />
    <AutomationsCreateDialog v-model:open="newAutomationDialogOpen" />
  </div>
</template>
<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline'
import type { Nullable } from '@speckle/shared'
import { automationsRoute, functionsRoute } from '~/lib/common/core/helpers/route'
import { useAutomationsStore } from '~/lib/frontend/automations/stores/automations'
import {
  ToastNotificationType,
  useGlobalToast
} from '~/lib/frontend/common/composables/toast'
import { useCoreStore } from '~/lib/frontend/core/stores/core'

import { useFunctionsStore } from '~/lib/frontend/functions/stores/functions'
const core = useCoreStore()

const { triggerNotification } = useGlobalToast()
const functions = useFunctionsStore()
const automations = useAutomationsStore()
const route = useRoute()
const router = useRouter()

const newFunctionDialogOpen = ref(false)
const newAutomationDialogOpen = ref(false)

if (process.client) {
  watch(
    () => route.query['ghAuth'],
    (ghAuthVal) => {
      if (!ghAuthVal?.length) return

      if (ghAuthVal === 'success') {
        triggerNotification({
          type: ToastNotificationType.Success,
          title: 'GitHub authorization successful'
        })
        newFunctionDialogOpen.value = true
      } else if (ghAuthVal === 'access_denied') {
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: 'GitHub authorization failed',
          description:
            "You've explicitly denied access to your GitHub account. Please try again."
        })
      } else {
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: 'GitHub authorization failed',
          description:
            (route.query['ghAuthDesc'] as Nullable<string>) ||
            'An unknown issue occurred'
        })
      }

      void router.replace({ query: {} })
    },
    { immediate: true }
  )
}
</script>
